import { Module } from "vuex";
import { mutations } from "./mutations";
import { actions } from "./actions";
import { OrderState } from "./types";
import { RootState } from "../types";

export const namespace = "order";

// Persisted to sessionStorage
export const state: () => OrderState = () => ({
  order: {
    version: 2,
    datum: "",
    uhrzeit: null,
    anzahlMedium: 0,
    anzahlLarge: 0,
    plzGebiete: [],
    anmerkungen: ""
  },
  uhrzeitCustomText: ""
});

export const order: Module<OrderState, RootState> = {
  namespaced: true,
  state,
  //getters,
  actions,
  mutations
};
