






















































































































































import Vue from "vue";

import { StoreUser } from "../store/models";

export default Vue.extend({
  // Excess router props
  inheritAttrs: false,
  data() {
    return {
      formUser: new StoreUser(),
      privacyTermsAccepted: false
    };
  },
  computed: {
    isLoggedIn(): boolean {
      return this.$store.getters["profile/isLoggedIn"];
    }
  },
  created() {
    this.$store.dispatch("profile/loadUser").then(storeUser => {
      if (storeUser) {
        this.formUser = storeUser;
        this.privacyTermsAccepted = true;
      }
    });
  },
  methods: {
    // Rely on HTML form validation
    onSubmit() {
      // @submit.prevent: evt.preventDefault();
      const isCreate = !this.isLoggedIn;
      this.$store.dispatch("profile/storeUser", this.formUser);
      if (isCreate) {
        // Trigger redirect
        this.$emit("user-submitted");
      } else {
        // TODO: Make this work in shadow DOM
        this.$bvToast.toast("Daten wurden aktualisiert", {
          title: "Konto",
          variant: "success",
          static: true
        });
      }
    },
    onReset() {
      if (this.isLoggedIn) {
        // Confirm
        this.$bvModal.show("user-reset-modal");
      } else {
        this.resetForm();
      }
    },
    resetForm() {
      const isDelete = this.isLoggedIn;
      // Clear store
      this.$store.commit("profile/profileDeleted");
      // Reset form values
      this.formUser = new StoreUser();
      this.privacyTermsAccepted = false;
      // Reset cookie settings
      this.$emit("user-reset");
      if (isDelete) {
        this.$bvToast.toast("Daten wurden gelöscht", {
          title: "Konto",
          variant: "danger",
          static: true
        });
      }
    }
  }
});
