import { User, User0, PersistedUser } from "./profile/types";

export class StoreUser implements User {
  version: 1 = 1;
  firma = "";
  ansprechpartner = "";
  email = "";
  telefon = "";
  strasse = "";
  plz = "";
  stadt = "";
  hinweise = "";

  [index: string]: any;

  static createFromPersistedUser(p: PersistedUser): StoreUser {
    const storeUser = new StoreUser();
    // Type guard
    // https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
    if (p.version === 0) {
      // p is of type User0. Migrate some properties...
      storeUser.email = p.email;
    } else {
      // p is of type User, but we are lazy
      for (const prop in p) {
        if (Object.prototype.hasOwnProperty.call(p, prop)) {
          storeUser[prop] = p[prop];
        }
      }
    }
    return storeUser;
  }
}
