// profile/actions.ts
import { ActionTree } from "vuex";
import { ProfileState, User } from "./types";
import { StoreUser } from "../models";
import { RootState } from "../types";

export const actions: ActionTree<ProfileState, RootState> = {
  loadUser({ state }): StoreUser | undefined {
    if (state.user) {
      return StoreUser.createFromPersistedUser(state.user);
    }
  },
  storeUser({ commit }, storeUser: StoreUser) {
    // Clone to shake off any reactivity from components
    const user: User = JSON.parse(JSON.stringify(storeUser));
    commit("profileLoaded", user);
  }
};
