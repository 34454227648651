// profile/getters.ts
import { GetterTree } from "vuex";
import { ProfileState } from "./types";
import { RootState } from "../types";

export const getters: GetterTree<ProfileState, RootState> = {
  isLoggedIn(state): boolean {
    return !!state.user;
  },
  authToken(state, _, rootState) {
    if (state.token) {
      return `Bearer ${state.token}`;
    } else if (rootState.history.orders.length) {
      return `OrderUUID ${rootState.history.orders[0].uuid}`;
    }
  }
};
