import { Module, GetterTree, MutationTree } from "vuex";
import { PersistedOrder } from "../order/types";
import { RootState } from "../types";

export const namespace = "history";

export interface HistoryState {
  orders: Array<PersistedOrder>;
}

// Persisted to localStorage
export const state: () => HistoryState = () => ({
  orders: []
});

export const getters: GetterTree<HistoryState, RootState> = {
  hasOrders(state): boolean {
    return state.orders.length > 0;
  }
};

export const mutations: MutationTree<HistoryState> = {
  orderSubmitted(state, payload: PersistedOrder) {
    state.orders.push(payload);
  }
};

export const history: Module<HistoryState, RootState> = {
  namespaced: true,
  state,
  getters,
  mutations
};
