// order/mutations.ts
import { MutationTree } from "vuex";
import { OrderState } from "./types";
import { state as orderStateFn } from "./index";

export const mutations: MutationTree<OrderState> = {
  updateDatum(state, datum) {
    state.order.datum = datum;
  },
  updateUhrzeit(state, uhrzeit) {
    state.order.uhrzeit = uhrzeit;
  },
  updateAnzahlMedium(state, anzahlMedium) {
    state.order.anzahlMedium = anzahlMedium;
  },
  updateAnzahlLarge(state, anzahlLarge) {
    state.order.anzahlLarge = anzahlLarge;
  },
  updatePlzGebiete(state, plzGebiete) {
    state.order.plzGebiete = plzGebiete;
  },
  updateAnmerkungen(state, anmerkungen) {
    state.order.anmerkungen = anmerkungen;
  },
  updateUhrzeitCustomText(state, uhrzeitCustomText) {
    state.uhrzeitCustomText = uhrzeitCustomText;
  },
  resetState(state) {
    Object.assign(state, orderStateFn());
  }
};
