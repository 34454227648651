
































































import Vue from "vue";
import { Component } from "vue/types";
import page from "page";

import Home from "./views/EinzelhandelHome.vue";
import Status from "./views/EinzelhandelStatus.vue";
import Account from "./views/EinzelhandelAccount.vue";

import store, { hasWebStorageCookie } from "./store";
import { HOURS_MAX } from "./store/defaults";

const EINZELHANDEL_HOME = "einzelhandel-home";
const EINZELHANDEL_STATUS = "einzelhandel-status";
const EINZELHANDEL_ACCOUNT = "einzelhandel-account";

export default Vue.extend({
  store,
  props: {
    lang: {
      type: String,
      default: "de"
    },
    hoursMax: {
      type: String,
      default: HOURS_MAX
    },
    uhrzeitCustomHint: {
      type: String,
      default: ""
    },
    anzahlMin: {
      type: Number,
      default: 1
    },
    labelAuftragPlzName: {
      type: String,
      default: ""
    },
    labelAuftragPlzHint: {
      type: String,
      default: ""
    }
  },
  data: function() {
    return {
      page,
      activeComponent: "loading-screen",
      pageContext: null,
      routes: {
        home: {
          component: EINZELHANDEL_HOME,
          path: "/"
        },
        status: {
          component: EINZELHANDEL_STATUS,
          path: "/status"
        },
        account: {
          component: EINZELHANDEL_ACCOUNT,
          path: "/konto"
        }
      },
      // Use as function
      hasWebStorageCookie
    };
  },
  // FIXME: $store not typed
  computed: {
    isLoggedIn(): boolean {
      return this.$store.getters["profile/isLoggedIn"];
    },
    hasOrders(): boolean {
      return this.$store.getters["history/hasOrders"];
    }
  },
  created() {
    if (process.env.NODE_ENV === "production") {
      page.base(document.location.pathname);
    }
    page(this.middleware);
    // HOME
    page(this.routes.home.path, () => {
      if (this.isLoggedIn) {
        this.activeComponent = this.routes.home.component;
      } else {
        page.redirect(this.routes.account.path);
      }
    });
    // STATUS
    page(this.routes.status.path, ctx => {
      if (this.hasOrders) {
        this.activeComponent = this.routes.status.component;
      } else {
        page.redirect(this.routes.account.path);
      }
    });
    // ACCOUNT
    page(this.routes.account.path, () => {
      this.activeComponent = this.routes.account.component;
    });
    page({ hashbang: true, click: false });
  },
  methods: {
    // TODO: page.js type definitions from @types/page
    middleware(ctx: any, next: Function) {
      this.pageContext = ctx;
      next();
    },
    async enableCookies() {
      await this.$store.dispatch("enableWebStorage");
      // Update hasWebStorageCookie
      this.$forceUpdate();
    },
    async disableCookies() {
      await this.$store.dispatch("disableWebStorage");
      this.$forceUpdate();
    }
  },
  // eslint-disable-next-line vue/order-in-components
  components: {
    "loading-screen": { render: h => h("div", "loading...") } as Component,
    [EINZELHANDEL_HOME]: Home,
    [EINZELHANDEL_STATUS]: Status,
    [EINZELHANDEL_ACCOUNT]: Account
  }
});

import {
  BadgePlugin,
  CardPlugin,
  FormPlugin,
  FormGroupPlugin,
  FormInputPlugin,
  FormSelectPlugin,
  FormCheckboxPlugin,
  FormTextareaPlugin,
  FormDatepickerPlugin,
  FormSpinbuttonPlugin,
  FormTagsPlugin,
  ButtonPlugin,
  AlertPlugin,
  ProgressPlugin,
  InputGroupPlugin,
  ModalPlugin,
  ToastPlugin,
  BIcon,
  BIconX,
  BIconCheck
} from "bootstrap-vue";
Vue.use(BadgePlugin);
Vue.use(CardPlugin);
Vue.use(FormPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(FormTextareaPlugin);
Vue.use(FormDatepickerPlugin);
Vue.use(FormSpinbuttonPlugin);
Vue.use(FormTagsPlugin);
Vue.use(ButtonPlugin);
Vue.use(AlertPlugin);
Vue.use(ProgressPlugin);
Vue.use(InputGroupPlugin);
Vue.use(ModalPlugin);
Vue.use(ToastPlugin);
Vue.component("BIcon", BIcon);
Vue.component("BIconX", BIconX);
Vue.component("BIconCheck", BIconCheck);
