








































import Vue from "vue";

import { PersistedOrder } from "../store/order/types";

export default Vue.extend({
  // Excess router props
  inheritAttrs: false,
  props: {
    page: {
      type: Object,
      default: null
    }
  },
  computed: {
    historyOrdersDesc(): Array<PersistedOrder> {
      return this.$store.state.history.orders.slice().reverse();
    },
    isNewOrder(): boolean {
      return /success/.test(this.page.querystring);
    }
  }
});
