import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import { RootState } from "./types";
import { profile, namespace as profileName } from "./profile/index";
import { order, namespace as orderName } from "./order/index";
import { history, namespace as historyName } from "./history/index";
import { setWebStorageCookie, removeWebStorageCookie, hasWebStorageCookie } from './cookies';
import VuexPersistence from "vuex-persist";

const VUEX_PERSIST_KEY = 'vuex';

Vue.use(Vuex);

hasWebStorageCookie() || (function() {
  // Clear web storage on load if cookie is missing
  window.localStorage.removeItem(VUEX_PERSIST_KEY);
  window.sessionStorage.removeItem(VUEX_PERSIST_KEY);
})();

const vuexLocal = new VuexPersistence<RootState>({
  key: VUEX_PERSIST_KEY,
  storage: window.localStorage,
  modules: [profileName, historyName],
  // TODO: Eval performance reading cookies
  filter: hasWebStorageCookie
});

const vuexSession = new VuexPersistence<RootState>({
  key: VUEX_PERSIST_KEY,
  storage: window.sessionStorage,
  modules: [orderName],
  filter: hasWebStorageCookie
});

const store: StoreOptions<RootState> = {
  // RootState not persisted
  state: {
    // For content negotiation with API
    version: process.env.VUE_APP_VERSION,
  },
  mutations: {    
    saveState() {
      // Trigger store plugin subscriptions
    }  
  },
  actions: {
    enableWebStorage({ commit }) {
      setWebStorageCookie();
      commit('saveState');
    },
    disableWebStorage() {
      removeWebStorageCookie()
      window.localStorage.removeItem(VUEX_PERSIST_KEY);
      window.sessionStorage.removeItem(VUEX_PERSIST_KEY);
    }
  },
  modules: {
    [profileName]: profile,
    [orderName]: order,
    [historyName]: history
  },
  plugins: [vuexLocal.plugin, vuexSession.plugin]
};

export { hasWebStorageCookie } from './cookies';
export default new Vuex.Store<RootState>(store);
