import { PersistedOrder } from "./order/types";

interface TempOrderAttributes {
  field_order_datum?: string;
  field_order_uhrzeit?: string;
  field_order_anzahl_medium?: number;
  field_order_anzahl_large?: number;
  field_order_plz_gebiete?: string[];
  field_order_anmerkungen?: string;
  field_profile_firma?: string;
  field_profile_ansprechpartner?: string;
  field_profile_temporder_email?: string;
  field_profile_telefon?: string;
  field_profile_strasse?: string;
  field_profile_plz?: string;
  field_profile_stadt?: string;
  field_profile_hinweise?: string;
  [key: string]: any;
}

// TODO: Generics for JSON:API
// TODO: Relationships
export class TempOrderEntity {
  type = "pwa_private_data--buchung_warenlieferung";
  attributes: TempOrderAttributes;

  constructor(o: PersistedOrder) {
    this.attributes = {
      field_order_datum: o.datum, // new Date(o.datum)
      field_order_uhrzeit: o.uhrzeitText || o.uhrzeit || "",
      field_order_anzahl_medium: o.anzahlMedium,
      field_order_anzahl_large: o.anzahlLarge,
      field_order_plz_gebiete: o.plzGebiete,
      field_order_anmerkungen: o.anmerkungen
    };
    if (o.user && o.user.version === 1) {
      this.attributes.field_profile_firma = o.user.firma;
      this.attributes.field_profile_ansprechpartner = o.user.ansprechpartner;
      this.attributes.field_profile_temporder_email = o.user.email;
      this.attributes.field_profile_telefon = o.user.telefon;
      this.attributes.field_profile_strasse = o.user.strasse;
      this.attributes.field_profile_plz = o.user.plz;
      this.attributes.field_profile_stadt = o.user.stadt;
      this.attributes.field_profile_hinweise = o.user.hinweise;
    }
  }
}
