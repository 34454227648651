













































































































































import Vue from "vue";

import { Order, PersistedOrder } from "../store/order/types";

import { HOURS_MAX } from "../store/defaults";
import { getISODateLocal } from "../utils";

const CUSTOM_UHRZEIT = "custom";

interface FormValidationState {
  datum?: boolean | null;
  uhrzeit?: boolean | null;
  anzahl: boolean | null;
  anmerkungen?: boolean | null;
}

export default Vue.extend({
  // Excess router props
  inheritAttrs: false,
  props: {
    hoursMax: {
      type: String,
      default: HOURS_MAX,
      validator: function(value) {
        const h23 = /^(?:2[0-3]|[01][0-9]):[0-5][0-9](:[0-5][0-9])?$/;
        return h23.test(value);
      }
    },
    uhrzeitCustomHint: {
      type: String,
      default: ""
    },
    anzahlMin: {
      type: Number,
      default: 1
    },
    labelAuftragPlzName: {
      type: String,
      default: "PLZ-Gebiete"
    },
    labelAuftragPlzHint: {
      type: String,
      default: ""
    }
  },
  data() {
    const now = new Date();
    return {
      CUSTOM_UHRZEIT,
      // FIXME: Fixed timezone
      // Assumed local timezone matches backend
      today: getISODateLocal(now),
      pastHoursMax:
        now >
        new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate(),
          ...this.hoursMax.split(":").map(Number)
        ),
      // Show additional non-native form validation feedback
      validated: false,
      loading: false
    };
  },
  computed: {
    // Computed active Order with defaults
    formOrder(): Order {
      return {
        version: this.$store.state.order.order.version,
        datum: this.orderDatum,
        uhrzeit: this.orderUhrzeit,
        uhrzeitText: this.orderUhrzeitText,
        anzahlMedium: this.orderAnzahlMedium,
        anzahlLarge: this.orderAnzahlLarge,
        plzGebiete: this.orderPlzGebiete,
        anmerkungen: this.orderAnmerkungen
      };
    },
    orderDatum: {
      get(): string {
        return this.$store.state.order.order.datum || this.today;
      },
      set(newValue: string) {
        this.$store.commit("order/updateDatum", newValue);
      }
    },
    orderUhrzeit: {
      get(): string {
        return this.$store.state.order.order.uhrzeit;
      },
      set(newValue: string) {
        this.$store.commit("order/updateUhrzeit", newValue);
      }
    },
    orderUhrzeitCustomText: {
      get(): string {
        return this.$store.state.order.uhrzeitCustomText;
      },
      set(newValue: string) {
        this.$store.commit("order/updateUhrzeitCustomText", newValue);
      }
    },
    orderUhrzeitText(): string {
      if (this.orderUhrzeit === CUSTOM_UHRZEIT) {
        return this.orderUhrzeitCustomText;
      }
      return (
        (this.$hoursOptions || []).find(
          option => option.value === this.orderUhrzeit
        )?.text || ""
      );
    },
    orderUhrzeitHint(): string {
      if (this.orderUhrzeit === CUSTOM_UHRZEIT) {
        return this.uhrzeitCustomHint;
      }
      return (
        (this.$hoursOptions || []).find(
          option => option.value === this.orderUhrzeit
        )?.hint || ""
      );
    },
    orderAnzahlMedium: {
      get(): number {
        return this.$store.state.order.order.anzahlMedium;
      },
      set(newValue: number) {
        this.$store.commit("order/updateAnzahlMedium", newValue);
      }
    },
    orderAnzahlLarge: {
      get(): number {
        return this.$store.state.order.order.anzahlLarge;
      },
      set(newValue: number) {
        this.$store.commit("order/updateAnzahlLarge", newValue);
      }
    },
    orderPlzGebiete: {
      get(): string[] {
        return this.$store.state.order.order.plzGebiete;
      },
      set(newValue: string[]) {
        this.$store.commit("order/updatePlzGebiete", newValue);
      }
    },
    orderAnmerkungen: {
      get(): string {
        return this.$store.state.order.order.anmerkungen;
      },
      set(newValue: string) {
        this.$store.commit("order/updateAnmerkungen", newValue);
      }
    },
    orderAnzahlValid(): boolean | null {
      return this.orderAnzahlMedium + this.orderAnzahlLarge >= this.anzahlMin
        ? null // Suppress valid feedback
        : false;
    }
  },
  methods: {
    anzahlFormatter(val: number): string {
      return !val ? "keine" : `${val} Stk.`;
    },
    onSubmit() {
      // Passed native form validation. Do some more validation
      if (this.orderAnzahlValid === false) {
        // Show form control feedback
        this.validated = true;
        return;
      }
      this.loading = true;
      this.$store
        .dispatch("order/submitOrder", this.formOrder)
        .then((o: PersistedOrder) => {
          this.resetForm();
          this.$emit("order-submitted", o.orderId);
        })
        .catch(err => {
          window.alert(err);
        })
        .finally(() => (this.loading = false));
    },
    resetForm() {
      this.$store.commit("order/resetState");
      this.validated = false;
    }
  }
});
