// profile/mutations.ts
import { MutationTree } from "vuex";
import { ProfileState, User } from "./types";

export const mutations: MutationTree<ProfileState> = {
  // Primer: https://codeburst.io/vuex-and-typescript-3427ba78cfa8
  // TODO: Fix names; add typed actions and mutations
  profileLoaded(state, payload: User) {
    state.user = payload;
  },
  profileDeleted(state) {
    state.user = undefined;
    state.token = undefined;
  }
};
