





























































import Vue from "vue";
import { VueTypedJs } from "vue-typed-js";
// Import directly. Compiled ESM adds sytles to <head>
import {
  SlideXLeftTransition,
  FadeTransition,
  ZoomCenterTransition
} from "vue2-transitions/src";

/* eslint-disable vue/no-unused-components */
export default Vue.extend({
  name: "Landing",
  components: {
    VueTypedJs,
    ZoomCenterTransition,
    SlideXLeftTransition,
    FadeTransition
  },
  props: {
    heading: {
      type: String,
      default: "Die Welt bewegt sich."
    },
    headingTyped: {
      type: String,
      default: "Wir bewegen uns mit!"
    },
    subHeading: {
      type: String,
      default: "Neue Dienstleistungen"
    },
    cardTitle: {
      type: String,
      default: "Warenlieferung"
    },
    cardBadge: {
      type: String,
      default: "Same Day"
    },
    cardLink: {
      type: String,
      default: "/einzelhandel/auftrag"
    },
    cardLinkText: {
      type: String,
      default: "Neuer Auftrag"
    },
    cardLinkMore: {
      type: String,
      default: "/einzelhandel"
    },
    cardLinkMoreText: {
      type: String,
      default: "Mehr Informationen"
    },
    cardLinkShuttle: {
      type: String,
      default: "#block-theisen-theisen-block"
    },
    cardLinkShuttleText: {
      type: String,
      default: "Zum Flughafentransfer"
    }
  }
});
