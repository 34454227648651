// order/actions.ts
import { ActionTree } from "vuex";
import { Order, PersistedOrder, OrderState } from "./types";
import { RootState } from "../types";
import { StoreUser } from "../models";
import { TempOrderEntity } from "../entities";

export const actions: ActionTree<OrderState, RootState> = {
  async submitOrder(
    { commit, dispatch, rootState, rootGetters },
    activeOrder: Order
  ) {
    const storeUser: StoreUser = await dispatch("profile/loadUser", null, {
      root: true
    });
    let order: PersistedOrder = Object.assign({}, activeOrder, {
      user: storeUser
    });
    // TODO: This is fine.. XD
    order = JSON.parse(JSON.stringify(order));
    const orderEntity = new TempOrderEntity(order);
    const response = await fetch(process.env.VUE_APP_API_URL, {
      method: "POST",
      // credentials: 'include'
      headers: {
        "Content-Type": "application/vnd.api+json",
        "X-App-Version": rootState.version
        //"Authorization": rootGetters['profile/authToken'] || ""
      },
      body: JSON.stringify({
        data: orderEntity
      })
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    const {
      data: {
        id: uuid,
        attributes: { drupal_internal__id: orderId }
      }
    } = await response.json();
    order.uuid = uuid;
    order.orderId = orderId;

    commit("history/orderSubmitted", order, { root: true });
    return order;
  }
};
