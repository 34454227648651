import Cookies from 'js-cookie';

const COOKIE_KEY_WEB_STORAGE = 'web_storage';
const COOKIE_VALUE_WEB_STORAGE = '1';
const COOKIE_EXPIRES = 90;

export function hasWebStorageCookie() : boolean {
  return Cookies.get(COOKIE_KEY_WEB_STORAGE) === COOKIE_VALUE_WEB_STORAGE;
}

export function setWebStorageCookie() {
  if (!hasWebStorageCookie()) {
    Cookies.set(COOKIE_KEY_WEB_STORAGE, COOKIE_VALUE_WEB_STORAGE, {
      expires: COOKIE_EXPIRES
    });
  }
}

export function removeWebStorageCookie() {
  Cookies.remove(COOKIE_KEY_WEB_STORAGE);
}
