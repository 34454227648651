import { Module } from "vuex";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { ProfileState } from "./types";
import { RootState } from "../types";

export const namespace = "profile";

// Persisted to localStorage
// TODO: Undefined as per tutorial, keep an eye on that (serialization)
export const state: () => ProfileState = () => ({
  user: undefined,
  token: undefined
});

export const profile: Module<ProfileState, RootState> = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
